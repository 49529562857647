import classNames from 'classnames';
import {Link} from 'gatsby';
import React from 'react';
import {IoIosArrowBack} from 'react-icons/io';
import styles from './pageTitle.module.scss';

export const PageTitle = (props) => {
  const classes = classNames(styles.pageTitle, props.className);

  return (
    <div className={classes}>
      {props.backHref &&
        <div className={styles.icon}>
          <Link to={props.backHref}>
            <IoIosArrowBack />
          </Link>
        </div>
      }
      <div>{props.text}</div>
    </div>
  );
};
