import React, {useState, useEffect} from 'react';
import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import {
  SubscriptionSummary,
} from 'src/components/subscription/subscriptionSummary';
import {PageTitle} from 'src/components/ui/pageTitle';
import {api} from 'src/shared/api';
import styles from './index.module.scss';

export default (props) => {
  const [loading, setLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    api().get('/api/subscriptions/')
        .then((response) => {
          setSubscriptions(response.data);
          if (response.data.length === 0) {
            window.location = '/subscription/new';
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log('Failed to get subscriptions', error);
        });
  }, []);

  return (
    <Layout>
      <div className={styles.pageWrapper}>
        <SEO title="Subscription" />
        <PageTitle text="Subscription" backHref="/account" />
        {loading && <div>Loading...</div>}
        {!loading &&
          <div>
            {subscriptions.map((subscription) => (
              <SubscriptionSummary
                key={`subscription-${subscription.pk}`}
                subscriptionId={subscription.pk}
                cardBrand={subscription.card_brand}
                cardLast4={subscription.card_last_4}
                cardExpMonth={subscription.card_exp_month}
                cardExpYear={subscription.card_exp_year}
                endedAt={subscription.ended_at}
              />
            ))}
            {subscriptions.length === 0 &&
              <div>No active subscriptions</div>
            }
          </div>
        }
      </div>
    </Layout>
  );
};
