import classNames from 'classnames';
import moment from 'moment';
import React, {useState} from 'react';
import {Button} from 'src/components/ui/button';
import {Card} from 'src/components/ui/card';
import {Modal, ModalBody, ModalFooter} from 'src/components/ui/modal/modal';
import {api} from 'src/shared/api';
import styles from './subscriptionSummary.module.scss';

export const SubscriptionSummary = (props) => {
  const [loading, setLoading] = useState(true);
  const [cancelModalActive, setCancelModalActive] = useState(false);
  const endedAt = moment(props.endedAt);

  const handleCancel = (subscriptionPk) => {
    api().patch(`/api/subscriptions/${subscriptionPk}/`, {
      cancel: true,
    }).then((response) => {
      setLoading(false);
      alert('Your subscription has been cancelled');
      setCancelModalActive(false);
    }).catch((error) => {
      setLoading(false);
      console.log('Failed to cancel subscriptions', error);
    });
  };

  return (
    <Card className={styles.subscription}>
      <div className={styles.name}>
        Pro
      </div>
      <div className={styles.expiration}>
        <StatusBadge endedAt={endedAt} />
      </div>
      <div className={styles.cardDetails}>
        <label>
          Card details
        </label>
        <span>
          {props.cardBrand}&nbsp;
          {props.cardLast4}
        </span>
        <span>
          Expires {`${props.cardExpMonth}/${props.cardExpYear}`}
        </span>
      </div>
      {!endedAt &&
        <>
          <div className={styles.actions}>
            <Button small onClick={() => setCancelModalActive(true)}>
              Cancel subscription
            </Button>
          </div>
          <Modal active={cancelModalActive}>
            <ModalBody>
              Are you sure you want to cancel your Pro subscription?
            </ModalBody>
            <ModalFooter className={styles.modalFooter}>
              <Button default onClick={() => setCancelModalActive(false)}>
                Back
              </Button>
              <Button danger onClick={() => handleCancel(props.subscriptionId)}>
                Cancel subscription
              </Button>
            </ModalFooter>
          </Modal>
        </>
      }
    </Card>
  );
};

const StatusBadge = (props) => {
  const cancelled = !!props.endedAt;
  const expired = props.endedAt && props.endedAt.isBefore(moment());
  const expirationDate = props.endedAt && props.endedAt.format('MMM D, YYYY');
  const verb = expired ? 'expired' : 'expires';
  const expirationText = cancelled && ` - ${verb} on ${expirationDate}`;
  const active = !props.endedAt || !expired;
  const statusText = active ? 'Active' : 'Cancelled';

  const badgeClasses = classNames(styles.statusBadge, {
    [styles.cancelled]: cancelled,
  });

  return (
    <div className={badgeClasses}>
      {statusText}{expirationText}
    </div>
  );
};
